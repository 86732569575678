body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.lightbeigebg {
  background-color: rgba(249, 236, 195, 1);
}

.activeicon {
  width: 32px;
  height: 32px;
  color: #df902a;
}

@media (max-width: 810px) {
  .mobilefullwidth {
    width: 100% !important;
  }
}

@media (max-width: 800px) {
  .toolbar {
    display: flex !important;
    flex-wrap: wrap!important;
  }
}

@media (min-width: 800px) {
  .searchbar {
    float: right;
    min-width: 400px;
  }
}

@media (min-width: 800px) {
  .addnotes {
    float: left;
    min-width: 350px;
  }
}

@media (max-width: 800px) {
  .searchbar {
    width: 100% !important;
  }
}

@media (max-width: 800px) {
  .addnotes {
    width: 100% !important;
  }
}

.pagination {
  display: flex;
  margin-bottom: 2px;
  margin: auto;
  justify-content: center;
  font-weight: bold;
}

.pagination li {
  display: inline-block;
  color: #000;
  cursor: pointer;
  margin-right: 3px;
  border-radius: 5px;
}

.pagination li a {
    padding: 2px 5px;
    display: inline-block;
    color: #000;
    outline: none;
}

.pagination li.active {
  background: #df902a;
  outline: none;
}